import { QueryEntity } from '@datorama/akita';
import { map } from 'rxjs/operators';

import { capsuleQuery } from '../capsule/capsule.query';
import {
  CapsulePollState,
  CapsulePollStore,
  capsulePollStore,
} from './capsule-poll.store';

export class CapsulePollQuery extends QueryEntity<CapsulePollState> {
  constructor(protected store: CapsulePollStore) {
    super(store);
  }

  activeCapsulePolls$ = this.selectAll().pipe(
    // tap(polls => console.log('capsuleSolutions', capsuleQuery.getActiveId(), polls)),
    map((polls) =>
      polls.filter((poll) => poll.capsuleId === capsuleQuery.getActiveId())
    )
  );
}

export const capsulePollQuery = new CapsulePollQuery(capsulePollStore);
