import { ID } from '@datorama/akita';
import { features } from '../../config/features';
import axios, { AxiosResponse } from 'axios';
import { from, iif, mergeMap, Observable, of, take } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { ROUTES } from '../../routes/routes';
import { userNotificationStore } from '../notification/notification.store';

import { solutionStore } from '../solution/solution.store';
import { Tag } from '../tag/tag.model';
import { CapsuleValidation } from './capsule-validators/capsule-validators.model';
import { CapsuleVote } from './capsule-vote/capsule-vote.model';
import {
  ActivateCapsuleDto,
  Capsule,
  CreateCapsuleDto,
  UpdateCapsuleDto,
} from './capsule.model';
import { CapsuleQuery, capsuleQuery } from './capsule.query';
import { CapsuleStore, capsuleStore } from './capsule.store';
import { userNotificationQuery } from '../notification/notification.query';
import { searchService } from '../search/search.service';
import { getCapsuleParticipatingUsersCount } from '../user/user-permissions.model';
import * as R from 'ramda';
import { CapsuleStatus } from './capsule-status/capsule-status.model';
import { openSuccessMessage } from '../modal/modal.model';
import { HomeType } from '@zdlibs/domain-capsule';
import {
  CommentTypes,
  getRealComments,
  getRealFeedbacks,
  PostCapsuleCommentReturnDto,
  RealComment,
  RealFeedback,
} from './capsule-comment/capsule-comment.model';

export class CapsuleService {
  constructor(
    private capsuleStore: CapsuleStore,
    private capsuleQuery: CapsuleQuery
  ) {}

  toggleCapsulesHome(): Observable<boolean | Capsule[]> {
    return this.getAllCapsules().pipe(
      tap(() => this.capsuleStore.toggleIsCapsulesFilteredView()),
      take(1)
    );
  }

  private getAllCapsules(): Observable<boolean | Capsule[]> {
    if (this.capsuleQuery.getValue().allCapsulesFetched) return of(false);
    return this.getCapsules('all' as HomeType.ALL).pipe(
      tap(() => this.capsuleStore.setCapsuleAsFetched())
    );
  }

  getCapsules(homeType?: HomeType): Observable<Capsule[]> {
    const type = homeType ? `/${homeType}` : '';
    return from(
      axios.get((window as any).env.REACT_APP_BACK_WEB_URL + '/capsules' + type)
    ).pipe(
      tap((res) => {
        res.data =
          res.data &&
          R.map((capsule: Capsule) => ({
            ...capsule,
            nbUsersParticipating: getCapsuleParticipatingUsersCount(capsule),
          }))(res.data);
        this.capsuleStore.set(res.data);
        searchService.setPositionFilters(res.data);
      }),
      map((res) => res.data)
    );
  }

  updateAllCapsules() {
    return from(
      axios.get((window as any).env.REACT_APP_BACK_WEB_URL + '/capsules/all')
    ).pipe(
      tap((res) => {
        res.data =
          res.data &&
          R.map((capsule: Capsule) => ({
            ...capsule,
            nbUsersParticipating: getCapsuleParticipatingUsersCount(capsule),
          }))(res.data);
        console.log(res.data);
        this.capsuleStore.upsertMany(res.data);
      }),
      map((res) => res.data),
      take(1)
    );
  }

  getOneCapsule(capsuleId: Capsule['id']): Observable<Capsule> {
    return from(
      axios.get(
        (window as any).env.REACT_APP_BACK_WEB_URL +
          '/capsules/' +
          capsuleId +
          '/updated'
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.update(res.data.id, () => ({
          ...res.data,
          nbUsersParticipating: getCapsuleParticipatingUsersCount(res.data),
        }));
      }),
      map((res) => res.data),
      take(1)
    );
  }

  getCapsuleInvitedUsers(capsuleId: ID): Observable<Capsule> {
    return from(
      axios.get(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/invited-users`
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            invitedUsers: res.data.invitedUsers,
          };
        });
      }),
      map(() => {
        return capsuleQuery.getEntity(capsuleId);
      })
    );
  }

  createCapsule(capsule: CreateCapsuleDto): Observable<Capsule> {
    const formData = new FormData();
    formData.append('title', capsule.title);
    formData.append('file', capsule.file);
    formData.append('description', capsule.description);
    formData.append('impacts', capsule.impacts);
    formData.append('tagIds', capsule.tagIds);

    if (!features.workflowPartage) {
      formData.append('constellationIds', capsule.constellationIds);
    }

    return from(
      axios.post(
        (window as any).env.REACT_APP_BACK_WEB_URL + '/capsules',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.add(res.data);
      }),
      map((res) => res.data)
    );
  }

  patchCapsule(
    capsuleId: ID,
    updateCapsuleDto: Partial<UpdateCapsuleDto>
  ): Observable<Capsule> {
    return from(
      axios.patch(
        `${(window as any).env.REACT_APP_BACK_WEB_URL}/capsules/${capsuleId}`,
        updateCapsuleDto
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return capsuleQuery.getEntity(capsuleId);
      }),
      take(1)
    );
  }

  patchCapsuleAssignee(capsuleId: ID, userId: ID): Observable<Capsule> {
    return from(
      axios.patch(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/switch-assignee`,
        { userId }
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return capsuleQuery.getEntity(capsuleId);
      }),
      take(1)
    );
  }

  patchCapsuleActivate(
    capsuleId: ID,
    activateCapsuleDto: ActivateCapsuleDto
  ): Observable<Capsule> {
    return from(
      axios.patch(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/activate`,
        activateCapsuleDto
      )
    ).pipe(
      tap((res) => {
        solutionStore.upsertMany([...res.data.solutions]);
      }),
      tap((res) => {
        delete res.data.solutions;
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return capsuleQuery.getEntity(capsuleId);
      })
    );
  }

  patchCapsuleValidation(
    capsuleId: ID,
    capsuleValidation: CapsuleValidation
  ): Observable<Capsule> {
    const { validationAt, user, ...restFields } = capsuleValidation;

    return from(
      axios.patch(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/validation`,
        restFields
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return capsuleQuery.getEntity(capsuleId);
      })
    );
  }

  deleteCapsuleValidation(
    capsuleId: ID,
    capsuleValidation: CapsuleValidation
  ): Observable<Capsule> {
    return from(
      axios.delete(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/validation/${capsuleValidation.userId}`
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return capsuleQuery.getEntity(capsuleId);
      })
    );
  }

  addCapsuleValidations(
    capsuleId: ID,
    capsuleValidationsIds: number[]
  ): Observable<Capsule> {
    return from(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/validation`,
        capsuleValidationsIds
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return capsuleQuery.getEntity(capsuleId);
      })
    );
  }

  addTag(capsuleId: ID, tag: Tag): Observable<Capsule> {
    return from(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/tags`,
        tag
      )
    ).pipe(
      tap(() => {
        this.updateAllCapsules().subscribe();
      }),

      map((res) => {
        return capsuleQuery.getEntity(capsuleId);
      })
    );
  }

  removeTag(capsuleId: ID, tag: Tag): Observable<Capsule> {
    return from(
      axios.delete(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/tags/${tag.id}`
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            ...res.data,
          };
        });
      }),
      tap(() => {
        this.updateAllCapsules().subscribe();
      }),
      map((res) => {
        return capsuleQuery.getEntity(capsuleId);
      })
    );
  }

  patchCapsuleAdvancement(
    capsuleId: ID,
    advancementStatus: number
  ): Observable<Capsule> {
    return from(
      axios.patch(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/advancement`,
        { advancementStatus }
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return capsuleQuery.getEntity(capsuleId);
      })
    );
  }

  patchCapsuleImage(capsuleId: ID, file: File): Observable<Capsule> {
    const formData = new FormData();
    formData.append('file', file);

    return from(
      axios.patch(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            ...res.data,
          };
        });
        this.toggleUploadingCapsuleImage();
      }),
      tap((res) => {
        const notificationRoute = ROUTES.CAPSULE_DETAIL(capsuleId);
        const notificationsToUpdate = userNotificationQuery.getAll();
        if (notificationsToUpdate.length > 0) {
          notificationsToUpdate
            .filter((un) => un.ctaPrimaryActionUrl === notificationRoute)
            .map((un) => {
              userNotificationStore.update(un.id, (userNotification) => {
                return {
                  ...userNotification,
                  imageUrl: res.data.imageFilename,
                };
              });
            });
        }
      }),
      map((res) => {
        return capsuleQuery.getEntity(capsuleId);
      })
    );
  }

  sharetoConstellations(
    capsuleId: ID,
    constellationsIds: string
  ): Observable<Capsule> {
    return from(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/share-to-constellations`,
        {
          constellationsIds,
        }
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return capsuleQuery.getEntity(capsuleId);
      })
    );
  }

  postCapsuleInviteUsers(capsuleId: ID, userIds: string): Observable<Capsule> {
    return from(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/invite`,
        { userIds }
      )
    ).pipe(
      map((res) => {
        return capsuleQuery.getEntity(capsuleId);
      })
    );
  }

  toggleFavorite(capsuleId: ID): Observable<Capsule> {
    return from(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/favorite`
      )
    ).pipe(
      first(),
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return capsuleQuery.getEntity(capsuleId);
      })
    );
  }

  toggleArchive(capsuleId: ID): Observable<Capsule> {
    return from(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/archive`
      )
    ).pipe(
      first(),
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return capsuleQuery.getEntity(capsuleId);
      })
    );
  }

  onFavorite(capsule: Capsule): Observable<Capsule> {
    return capsuleService.toggleFavorite(capsule.id).pipe(first());
  }

  vote(capsuleId: ID, capsuleVote: CapsuleVote): Observable<Capsule> {
    return from(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/vote`,
        capsuleVote
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return capsuleQuery.getEntity(capsuleId);
      })
    );
  }

  //  _____                                      _
  // /  __ \                                    | |
  // | /  \/ ___  _ __ ___  _ __ ___   ___ _ __ | |_ ___
  // | |    / _ \| '_ ` _ \| '_ ` _ \ / _ \ '_ \| __/ __|
  // | \__/\ (_) | | | | | | | | | | |  __/ | | | |_\__ \
  //  \____/\___/|_| |_| |_|_| |_| |_|\___|_| |_|\__|___/

  getCapsuleTopComments(capsuleId: ID): Observable<Capsule> {
    this.toggleLoadingComments();
    return from(
      axios.get(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/top-comments`
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            comments: res.data,
          };
        });
        this.toggleLoadingComments();
      }),
      map(() => {
        return capsuleQuery.getEntity(capsuleId);
      })
    );
  }

  getCapsuleComments(capsuleId: ID): Observable<Capsule> {
    this.toggleLoadingComments();
    return from(
      axios.get(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/comments`
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            comments: res.data,
          };
        });
        this.toggleLoadingComments();
      }),
      map(() => {
        return capsuleQuery.getEntity(capsuleId);
      }),
      take(1)
    );
  }

  postCapsuleComment(
    capsuleId: ID,
    text: string,
    rate?: number
  ): Observable<Capsule> {
    return from(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/comments`,
        { text, rate }
      )
    ).pipe(
      tap((res: AxiosResponse<PostCapsuleCommentReturnDto>) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          const { comment, ...updatedCapsule } = res.data;
          return {
            ...capsule,
            ...updatedCapsule,
            comments: [comment, ...capsule.comments],
          };
        });
      }),
      map(() => {
        return capsuleQuery.getEntity(capsuleId);
      }),
      take(1)
    );
  }

  postCapsuleRating(capsuleId: ID, rate: number): Observable<Capsule> {
    return from(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/comments`,
        { text: '', rate }
      )
    ).pipe(
      tap((res: AxiosResponse<PostCapsuleCommentReturnDto>) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          const { comment, ...updatedCapsule } = res.data;
          return {
            ...capsule,
            ...updatedCapsule,

            comments: [...capsule.comments, comment],
          };
        });
      }),
      map(() => {
        return capsuleQuery.getEntity(capsuleId);
      }),
      take(1)
    );
  }

  patchCapsuleComment(
    capsuleId: ID,
    commentId: ID,
    text: string,
    rate?: number
  ): Observable<Capsule> {
    return from(
      axios.patch(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/comments/${commentId}`,
        { text, rate }
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          // res.data is CapsuleComment
          const comments = [...capsule.comments];
          const idx = comments.findIndex((comment) => comment.id === commentId);
          comments[idx] = { ...comments[idx], ...res.data };
          return {
            ...capsule,
            comments,
          };
        });
      }),
      map(() => {
        return capsuleQuery.getEntity(capsuleId);
      }),
      take(1)
    );
  }

  removeCapsuleComment(capsuleId: ID, commentId: ID): Observable<Capsule> {
    return from(
      axios.delete(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/comments/${commentId}`
      )
    ).pipe(
      tap(() => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          const comments = [...capsule.comments];
          const idx = comments.findIndex((comment) => comment.id === commentId);
          comments[idx] = null;
          return {
            ...capsule,
            nbComments: capsule.nbComments - 1,
            comments: comments.filter(Boolean),
          };
        });
      }),
      map(() => {
        return capsuleQuery.getEntity(capsuleId);
      }),
      take(1)
    );
  }

  // 	    ___  _   _             _                          _
  // 	   / _ \| | | |           | |                        | |
  //    / /_\ \ |_| |_ __ _  ___| |__  _ __ ___   ___ _ __ | |_ ___
  //    |  _  | __| __/ _` |/ __| '_ \| '_ ` _ \ / _ \ '_ \| __/ __|
  //    | | | | |_| || (_| | (__| | | | | | | | |  __/ | | | |_\__ \
  //    \_| |_/\__|\__\__,_|\___|_| |_|_| |_| |_|\___|_| |_|\__|___/

  getCapsuleAttachments(capsuleId: ID): Observable<Capsule> {
    return from(
      axios.get(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/attachments`
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            attachments: res.data.attachments,
          };
        });
      }),
      map((res) => {
        return capsuleQuery.getEntity(capsuleId);
      }),
      take(1)
    );
  }

  postAttachmentToCapsule(capsuleId: ID, files: File[]): Observable<Capsule> {
    const formData = new FormData();
    formData.append('file', files[0]);

    return from(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/attachments`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            ...res.data,
          };
        });
        this.toggleUploadingDocument();
      }),
      map((res) => {
        return capsuleQuery.getEntity(capsuleId);
      })
    );
  }

  deleteCapsuleAttachment(
    capsuleId: ID,
    attachmentId: ID
  ): Observable<Capsule> {
    return from(
      axios.delete(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/attachments/${attachmentId}`
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return capsuleQuery.getEntity(capsuleId);
      })
    );
  }

  //   _____ _                   _          _
  //  /  ___| |                 | |        | |
  //  \ `--.| |_ ___  _ __ ___  | |__   ___| |_ __   ___ _ __ ___
  //   `--. \ __/ _ \| '__/ _ \ | '_ \ / _ \ | '_ \ / _ \ '__/ __|
  //  /\__/ / || (_) | | |  __/ | | | |  __/ | |_) |  __/ |  \__ \
  //  \____/ \__\___/|_|  \___| |_| |_|\___|_| .__/ \___|_|  |___/
  // 										| |
  // 										|_|

  // TODO: This should be in session.

  toggleUploadingDocument(): Observable<Capsule> {
    return this.capsuleStore.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        isUploadingDocument: !state.ui.isUploadingDocument,
      },
    }));
  }

  toggleUploadingCapsuleImage(): Observable<Capsule> {
    return this.capsuleStore.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        isUploadingCapsuleImage: !state.ui.isUploadingCapsuleImage,
      },
    }));
  }

  toggleLoadingComments(): Observable<Capsule> {
    return this.capsuleStore.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        isLoadingComments: !state.ui.isLoadingComments,
      },
    }));
  }

  closeCapsule(
    capsuleId: Capsule['id'],
    resolved?: boolean
  ): Observable<Capsule['id']> {
    const status = resolved
      ? CapsuleStatus.FINISHED
      : CapsuleStatus.NOT_RESOLVED;
    return from(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/finish`,
        {
          status,
        }
      )
    ).pipe(
      tap((res) => {
        this.capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            ...res.data,
          };
        });
        openSuccessMessage('La capsule a été mise à jour');
      }),
      map(() => capsuleId)
    );
  }

  getComments(
    capsuleId: Capsule['id'],
    commentTypes: CommentTypes
  ): Observable<{
    comments: RealComment[] | RealFeedback[];
    hasUserGivenFeedback: Capsule['hasUserGivenFeedback'];
  }> {
    const getCommentTypes =
      commentTypes === 'comment' ? getRealComments : getRealFeedbacks;
    return this.capsuleQuery.selectEntity(capsuleId).pipe(
      mergeMap((capsule) =>
        iif(
          () => !capsule.comments,
          this.getCapsuleComments(capsule.id),
          of(capsule)
        )
      ),
      map((capsule) => ({
        comments: getCommentTypes(capsule.comments),
        hasUserGivenFeedback: capsule.hasUserGivenFeedback,
      })),
      take(1)
    );
  }

  // getAccountCapsuleNumbers(): Observable<CapsuleCount> {
  // 	return from(axios.get(`${(window as any).env.REACT_APP_BACK_WEB_URL}/users/me/capsules-count`)).pipe(
  // 		map(res => {
  // 			return res.data
  // 		}),
  // 	)
  // }

  // fetchAccountCapsules(status: AccountCapsuleStatus): Observable<Capsule[]> {
  // 	return fromRequest(
  // 		axios.get(`${(window as any).env.REACT_APP_BACK_WEB_URL}/users/me/capsules`, {
  // 			params: {
  // 				status,
  // 			},
  // 		}),
  // 	).pipe(
  // 		map(res => {
  // 			if (!res) {
  // 				return []
  // 			}
  // 			return res.data
  // 		}),
  // 	)
  // }

  // postCapsule(
  // 	capsule: Capsule,
  // 	file: File,
  // 	attachments: File[],
  // 	scopePeer: boolean,
  // 	scopeTeam: boolean,
  // ): Observable<Capsule> {
  // 	const capsuleFormData = new FormData()
  // 	capsuleFormData.append('title', capsule.title)
  // 	capsuleFormData.append('description', capsule.description)
  // 	// capsuleFormData.append('tags', capsule.tags.join(','))
  // 	capsuleFormData.append('inManagedConstellation', scopeTeam ? 'true' : 'false')
  // 	capsuleFormData.append('inMemberedConstellation', scopePeer ? 'true' : 'false')
  // 	if (file) {
  // 		capsuleFormData.append('file', file)
  // 	}
  // 	return fromRequest(
  // 		axios.post((window as any).env.REACT_APP_BACK_WEB_URL + '/capsules', capsuleFormData, {
  // 			headers: { 'Content-Type': undefined },
  // 		}),
  // 	).pipe(
  // 		switchMap(res => {
  // 			if (attachments && attachments.length > 0) {
  // 				return this.postAttachments(res.data.id, attachments).pipe(
  // 					map(() => {
  // 						this.capsuleStore.add(res.data)
  // 						return res.data
  // 					}),
  // 				)
  // 			}
  // 			this.capsuleStore.add(res.data)
  // 			return of(res.data)
  // 		}),
  // 	)
  // }

  // postAttachments(capsuleId: string, attachments: File[]): Observable<any> {
  // 	const attachmentsFormData = new FormData()
  // 	attachments.forEach(attachment => attachmentsFormData.append('attachments[]', attachment))
  // 	return from(
  // 		axios.post(`${(window as any).env.REACT_APP_BACK_WEB_URL}/capsules/${capsuleId}/attachments`, attachmentsFormData, {
  // 			headers: { 'Content-Type': undefined },
  // 		}),
  // 	)
  // }

  // upCapsule(capsule: Capsule): Observable<Capsule> {
  // 	return from(axios.patch(`${(window as any).env.REACT_APP_BACK_WEB_URL}/capsules/${capsule.id}/up`)).pipe(
  // 		map(res => {
  // 			this.capsuleStore.update(capsule.id, { isPeerCapsule: true })
  // 			return res.data
  // 		}),
  // 	)
  // }

  // downCapsule(capsule: Capsule): Observable<Capsule> {
  // 	return from(axios.patch(`${(window as any).env.REACT_APP_BACK_WEB_URL}/capsules/${capsule.id}/down`)).pipe(
  // 		map(res => {
  // 			this.capsuleStore.update(capsule.id, { isTeamCapsule: true })
  // 			return res.data
  // 		}),
  // 	)
  // }

  // propelCapsule(
  // 	capsuleId: string,
  // 	timeEstimation: CapsuleTimeEstimation,
  // 	priority: CapsulePriority,
  // 	assignedToIds: ID[],
  // ): Observable<any> {
  // 	return from(
  // 		axios.post(`${(window as any).env.REACT_APP_BACK_WEB_URL}/capsules/${capsuleId}/propel`, {
  // 			timeEstimation,
  // 			priority,
  // 			assignedToIds,
  // 		}),
  // 	).pipe(
  // 		map(res => {
  // 			if (assignedToIds.includes(sessionService.currentUser().id.toString())) {
  // 				const newAssignment = createAssignment()
  // 				newAssignment.id = uuid()
  // 				newAssignment.assignedTo = sessionService.currentUser()
  // 				assignmentStore.add(newAssignment)
  // 			}
  // 			this.capsuleStore.update(capsuleId, { isPropelled: true })
  // 			return res.data
  // 		}),
  // 	)
  // }

  // activateCapsule(capsuleId: string, shouldEndAt: string): Observable<any> {
  // 	return from(
  // 		axios.patch(`${(window as any).env.REACT_APP_BACK_WEB_URL}/capsules/${capsuleId}/activate`, {
  // 			shouldEndAt: shouldEndAt,
  // 		}),
  // 	).pipe(
  // 		map(() => {
  // 			setTimeout(
  // 				() =>
  // 					this.capsuleStore.update(capsuleId, {
  // 						capsuleStatus: CapsuleStatus.ACTIVATED,
  // 						shouldEndAt,
  // 					}),
  // 				3000,
  // 			)
  // 		}),
  // 	)
  // }

  // likeCapsule(capsule: Capsule): Observable<Capsule> {
  // 	return fromRequest(axios.patch(`${(window as any).env.REACT_APP_BACK_WEB_URL}/capsules/${capsule.id}/like`)).pipe(
  // 		map(res => {
  // 			this.capsuleStore.update(capsule.id, { liked: true, nbLikes: capsule.nbLikes + 1 })
  // 			return res.data
  // 		}),
  // 	)
  // }

  // rateCapsule(capsuleId: string, rate: CapsuleRate, comment: string): Observable<any> {
  // 	return fromRequest(
  // 		axios.post(`${(window as any).env.REACT_APP_BACK_WEB_URL}/capsules/${capsuleId}/rate`, {
  // 			rate: rate,
  // 			comment: comment,
  // 		}),
  // 	).pipe(
  // 		map(res => {
  // 			this.capsuleStore.update(capsuleId, { isRated: true })
  // 			const newRate = createCapsuleRating()
  // 			newRate.comment = comment
  // 			newRate.rate = rate
  // 			newRate.ratedBy = sessionService.currentUser()
  // 			capsuleRatingStore.add(newRate)
  // 			return res.data
  // 		}),
  // 	)
  // }

  // updateCapsule(capsuleId: ID, title: string, description: string, tags: string[]): Observable<any> {
  // 	return from(
  // 		axios.patch(`${(window as any).env.REACT_APP_BACK_WEB_URL}/capsules/${capsuleId}`, {
  // 			title: title,
  // 			description: description,
  // 			tags: tags,
  // 		}),
  // 	)
  // }

  // updateCapsulePicture(capsuleId: ID, picture: File) {
  // 	const capsuleFormData = new FormData()
  // 	capsuleFormData.append('picture', picture)
  // 	return from(
  // 		axios.patch(`${(window as any).env.REACT_APP_BACK_WEB_URL}/capsules/${capsuleId}/picture`, capsuleFormData),
  // 	).pipe(map(res => res.data))
  // }

  // deleteCapsule(capsuleId: ID): Observable<any> {
  // 	return fromRequest(axios.delete(`${(window as any).env.REACT_APP_BACK_WEB_URL}/capsules/${capsuleId}`)).pipe(
  // 		map(() => {
  // 			this.capsuleStore.remove(capsuleId)
  // 		}),
  // 	)
  // }

  // reopenCapsule(capsuleId: ID): Observable<any> {
  // 	return fromRequest(axios.patch(`${(window as any).env.REACT_APP_BACK_WEB_URL}/capsules/${capsuleId}/reopen`)).pipe(
  // 		map(res => {
  // 			this.capsuleStore.update(capsuleId, { status: CapsuleStatus.ASSIGNED })
  // 			return res.data
  // 		}),
  // 	)
  // }
}

export const capsuleService = new CapsuleService(capsuleStore, capsuleQuery);
