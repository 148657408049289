import moment from 'moment-timezone';
import { Capsule } from '../store/capsule/capsule.model';
import { DateRange } from '../store/dashboard/date-range.enum';
import { isDateString } from './validate';

export const userTimeZone = moment.tz.guess();

/**
 * Formats a date to readable value from now.
 * Dates coming from server are always UTC.
 *
 *
 * Examples:
 * dateFromNow(now) // "Il y a quelques secondes."
 * dateFromNow(lastWekk) // "05/09/2020 - 13:37"
 *
 * @param {(Date | string)} date
 * @return {*}  {string}
 */
export const dateFromNow = (
  date: Date | string,
  includePrefix: boolean = false
): string => {
  const daysDifference: number = moment().diff(date, 'days');

  if (daysDifference >= 1) {
    let formattedDate = formatToLocalDate(date);
    if (includePrefix) {
      formattedDate = `le ${formattedDate}`;
    }
    return replaceTooLongString(formattedDate);
  } else {
    return replaceTooLongString(moment(date).fromNow());
  }
};

export const daysBetweenDate = (date1: Date, date2: Date): number => {
  const ONE_DAY = 1000 * 60 * 60 * 24;
  const difference_ms = msBetweenDate(date1, date2);
  return Math.round(difference_ms / ONE_DAY);
};

export const msBetweenDate = (date1: Date, date2: Date): MilliSec => {
  // The number of milliseconds in one day
  // var ONE_DAY = 1000 * 60 * 60 * 24

  // Convert both dates to milliseconds
  const date1_ms = getMs(date1);
  const date2_ms = getMs(date2);

  // Calculate the difference in milliseconds
  const difference_ms = Math.abs(date1_ms - date2_ms);

  // Convert back to days and return
  return difference_ms as MilliSec;
};

export const addDayToDate = (date: Date, days: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};
export const removeDayToDate = (date: Date, days: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() - days);
  return newDate;
};

export const addMsToDate = (date: Date, ms: MilliSec): MilliSec => {
  var date1_ms = moment(date).toDate().getTime() as MilliSec;
  return (date1_ms + ms) as MilliSec;
};
export type MilliSec = Brand<number, 'MilliSec'>;
export const getMs = (date: Date): MilliSec => {
  return moment(date).toDate().getTime() as MilliSec;
};
const replaceTooLongString = (formattedDate: string) =>
  formattedDate.replace(
    /il y a quelques secondes|dans quelques secondes/,
    `à l'instant`
  );

export const getIsoDate = (dateFr: string): Date => {
  return moment(dateFr, 'DD/MM/YYYY').toDate();
};

export const formatToLocalDate = (
  date: Date | string,
  format: string = 'DD/MM/YYYY à HH:mm'
): string => {
  if (!date) return '';
  return moment.utc(date).local().format(format);
};

const shouldHalfAtThisDate = (
  activatedAt: Date,
  halfTimeLifeSolutionActivation: MilliSec
) => addMsToDate(activatedAt, halfTimeLifeSolutionActivation);

export const isLate = (
  activatedAt: Date,
  halfTimeLifeSolutionActivation: MilliSec,
  today: MilliSec,
  advancementStatus: Capsule['advancementStatus']
): boolean =>
  shouldHalfAtThisDate(activatedAt, halfTimeLifeSolutionActivation) < today &&
  advancementStatus < 50;

const weekDaysFormatter = (weeks: number): string[] => {
  const categories = [];
  const startDate = moment().add(1, 'day').subtract(weeks, 'week');
  const endDate = startDate.clone().add(weeks, 'week');
  let currentDate = startDate.clone();
  while (currentDate < endDate) {
    if (currentDate.weekday() !== 5 && currentDate.weekday() !== 6) {
      categories.push(currentDate.format('ddd'));
    }
    currentDate = currentDate.clone().add(1, 'day');
  }

  return categories;
};

const quartersFormatter = (quarter: number): string[] => {
  const categories = [];
  const startDate = moment().add(1, 'day').subtract(quarter, 'quarter');
  const endDate = startDate.clone().add(quarter, 'quarter');
  let currentDate = startDate.clone();
  while (currentDate < endDate) {
    categories.push(`S ${currentDate.format('W')}`);
    currentDate = currentDate.clone().add(1, 'week');
  }
  return categories;
};

const yearsFormatter = (year: number): string[] => {
  const categories = [];
  const startDate = moment().add(1, 'month').subtract(year, 'year');
  const endDate = startDate.clone().add(year, 'year');
  let currentDate = startDate.clone();
  while (currentDate < endDate) {
    categories.push(currentDate.format('MMM'));
    currentDate = currentDate.clone().add(1, 'month');
  }
  return categories;
};

export const xAxisDisplayDate = (selectedRange: DateRange): string[] => {
  switch (selectedRange) {
    case DateRange.PAST_THREE_WEEKS:
      return weekDaysFormatter(3);
    case DateRange.PAST_TWO_WEEKS:
      return weekDaysFormatter(2);
    case DateRange.PAST_WEEK:
      return weekDaysFormatter(1);
    case DateRange.TODAY:
      return ["Aujourd'hui"];
    case DateRange.TRIMESTER:
      return quartersFormatter(1);
    case DateRange.YEAR:
      return yearsFormatter(1);
    case DateRange.YESTERDAY:
      return ['Hier'];
  }
  return [];
};

export const transformToDate = <T>(orderBy: keyof T, value: any): number => {
  if (value === '') return null;
  if (isDateString(value)) {
    value = getIsoDate(value);
  }
  return new Date(value).getTime();
};
