import {
  isCapsuleCreator,
  isCapsulePilote,
  isReferentOrAdmin,
} from '@zdlibs/domain/user';
import { User } from '../../user/user-permissions.model';
import { Capsule } from '../capsule.model';

export enum CapsuleStatus {
  CREATED = 'created',
  PENDING_ACTIVATION = 'pending_activation',
  ACTIVATED = 'activated',
  FINISHED = 'finished',
  NOT_RESOLVED = 'not_resolved',
}

export enum CapsuleItemStatusColors {
  PROGRESS = 'progress',
  ARCHIVED = 'archived',
  PENDING = 'pending',
  NEW = 'new',
  ACTION = 'action',
  RESOLVED = 'resolved',
  NOT_RESOLVED = 'notResolved',
}

export const statusColors = {
  [CapsuleItemStatusColors.ACTION]: '#FFC100',
  [CapsuleItemStatusColors.NEW]: '#e10053',
  [CapsuleItemStatusColors.PENDING]: '#019DBA',
  [CapsuleItemStatusColors.PROGRESS]: '#F7B267',
  [CapsuleItemStatusColors.RESOLVED]: '#008148',
  [CapsuleItemStatusColors.NOT_RESOLVED]: '#191919',
  [CapsuleItemStatusColors.ARCHIVED]: '#496D93',
};

export const graphStatusColors = {
  [CapsuleStatus.CREATED]: statusColors[CapsuleItemStatusColors.NEW],
  [CapsuleStatus.PENDING_ACTIVATION]:
    statusColors[CapsuleItemStatusColors.PENDING],
  [CapsuleStatus.ACTIVATED]: statusColors[CapsuleItemStatusColors.PROGRESS],
  [CapsuleStatus.FINISHED]: statusColors[CapsuleItemStatusColors.RESOLVED],
};

export const getCapsuleItemStatusColors = (
  capsule: Capsule
): CapsuleItemStatusColors => {
  if (isArchived(capsule)) return CapsuleItemStatusColors.ARCHIVED;
  if (isPending(capsule)) return CapsuleItemStatusColors.PENDING;
  if (isNotResolved(capsule)) return CapsuleItemStatusColors.NOT_RESOLVED;
  if (isNew(capsule)) return CapsuleItemStatusColors.NEW;
  if (isInProgress(capsule)) return CapsuleItemStatusColors.PROGRESS;
  if (isResolved(capsule)) return CapsuleItemStatusColors.RESOLVED;
  return CapsuleItemStatusColors.ACTION;
};

//PHASE

export const reactivateCapsule = (capsule: Capsule): boolean =>
  isPending(capsule);

export const activateCapsulePhase = (capsule: Capsule) =>
  isNew(capsule) && capsule.nbSolutions > 0;

export const isArchived = (capsule: Pick<Capsule, 'archived'>) =>
  capsule.archived;

//STATUS

export const isNew = (capsule: Capsule) =>
  capsule.capsuleStatus === CapsuleStatus.CREATED;

export const isPending = (
  capsule: PartialWithRequired<Capsule, 'capsuleStatus'>
) => capsule.capsuleStatus === CapsuleStatus.PENDING_ACTIVATION;

export const isInProgress = (capsule: Capsule) =>
  capsule.capsuleStatus === CapsuleStatus.ACTIVATED &&
  capsule.advancementStatus > 0 &&
  capsule.advancementStatus < 100;

export const isNotResolved = (
  capsule: Pick<Capsule, 'capsuleStatus'>
): boolean => capsule.capsuleStatus === CapsuleStatus.NOT_RESOLVED;

export const isResolved = (capsule: Pick<Capsule, 'capsuleStatus'>): boolean =>
  capsule.capsuleStatus === CapsuleStatus.FINISHED;

export const isClosed = (
  capsule: Pick<Capsule, 'capsuleStatus' | 'archived'>
): boolean =>
  isResolved(capsule) || isNotResolved(capsule) || isArchived(capsule);

export const countCapsulesByStatus =
  (capsules: Capsule[]) =>
  (filter: Predicate<any>): number =>
    capsules.filter(filter)?.length || 0;

// other

export const couldHandleActivationCapsule = (
  capsule: Capsule,
  user: User
): boolean =>
  isReferentOrAdmin(user) ||
  isCapsulePilote(user)(capsule) ||
  isCapsuleCreator(user)(capsule);

export const getWordingForCapsuleStatus = (capsuleStatus: CapsuleStatus) => {
  switch (capsuleStatus) {
    case CapsuleStatus.ACTIVATED:
      return 'En Progression';
    case CapsuleStatus.CREATED:
      return 'Nouveau';
    case CapsuleStatus.FINISHED:
      return 'Résolu';
    case CapsuleStatus.NOT_RESOLVED:
      return 'Non-résolu';
    case CapsuleStatus.PENDING_ACTIVATION:
      return 'En attente';
    default:
      return 'Archivé';
  }
};
export const CAPSULE_STATUS_TITLE = {
  [CapsuleStatus.CREATED]: 'Nouvelles capsules',
  [CapsuleStatus.PENDING_ACTIVATION]: 'Capsules en attente',
  [CapsuleStatus.ACTIVATED]: 'Capsules en progression',
  [CapsuleStatus.FINISHED]: 'Capsule résolues',
};
