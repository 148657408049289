import { QueryEntity } from '@datorama/akita';

import { UserState, UserStore, userStore } from './user.store';

export class UserQuery extends QueryEntity<UserState> {
  constructor(protected store: UserStore) {
    super(store);
  }

  users$ = this.selectAll({
    filterBy: (user) => !user.deleted,
  });
  isUploadingImage$ = this.select((state) => state.ui.isUploadingImage);
}

export const userQuery = new UserQuery(userStore);
