import axios from 'axios';
import { Session } from 'inspector';
import { from, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { SettingsHelpSection } from '../../components/organisms/SettingsHelp/settingsHelp-section.enum';
import { UserToInvite } from '../../store/user/user-to-invite.model';
import { User } from '../user/user-permissions.model';
import { ResetPassword } from './reset-password.model';
import { sessionQuery } from './session.query';
import { SessionStore, sessionStore } from './session.store';

import { fromRequest } from '../../utils/utils';
import { formDataHelper } from '../storage-utils';
import { byPassLoginOnDevEnv } from './byPassLoginOnDevEnv';

export class SessionService {
  constructor(private sessionStore: SessionStore) {}

  login(email: string, password: string): Observable<Session> {
    return from(
      axios.post(`${(window as any).env.REACT_APP_BACK_WEB_URL}/auth/login`, {
        email,
        password,
      })
    ).pipe(
      tap(
        (res) => {
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + res.data.accessToken;
          this.sessionStore.login(res.data);
        },
        (err) => {
          throw new Error(this.handleError(err.response.data.message));
        }
      ),
      map((res) => {
        return res.data;
      })
    );
  }

  sendLoginLink(email: string) {
    return from(
      axios.post(
        `${(window as any).env.REACT_APP_BACK_WEB_URL}/auth/login-link`,
        {
          email,
        }
      )
    ).pipe(
      tap({
        next: (res) => {
          byPassLoginOnDevEnv(res.data, this.sessionStore);
          console.log('success');
        },
        error: (err) => {
          console.log('err :' + err);
          if (err.response !== undefined) {
            throw new Error(this.handleError(err.response.data.message));
          } else {
            throw err;
          }
        },
      }),
      map((res) => {
        return res.data;
      })
    );
  }

  verifyLoginLink(code: string) {
    return from(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/auth/login-link-verification`,
        {
          code,
        }
      )
    ).pipe(
      tap(
        (res) => {
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + res.data.accessToken;
          this.sessionStore.login(res.data);
        },
        (err) => {
          throw new Error(this.handleError(err.response.data.message));
        }
      ),
      map((res) => {
        return res.data;
      })
    );
  }

  setHeaderAccessToken(): Observable<boolean> {
    return sessionQuery.isLoggedIn$.pipe(
      tap((isLoggedIn) => {
        if (isLoggedIn) {
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + sessionQuery.getValue().accessToken;
        }
      })
    );
  }

  currentUser(): User {
    return sessionQuery.getValue().user;
  }

  currentUser$(): Observable<User> {
    return sessionQuery.currentUser$;
  }

  getToken(): string {
    return sessionQuery.getValue().accessToken;
  }

  logout() {
    axios.defaults.headers.common['Authorization'] = null;
    sessionStore.logout();
  }

  handleError(message: any): string {
    switch (message) {
      case 'EMAIL_NOT_FOUND':
        return "Cet email n'existe pas";
      case 'PASSWORD_DONT_MATCH':
        return 'Mot de passe invalide';
      case 'USER_IS_DISABLED':
        return 'Cet utilisateur est désactivé';
      case 'USER_IS_DELETED':
        return 'Cet utilisateur a été supprimé';
      case 'USER_IS_NOT_VERIFIED':
        return 'Vous devez vérifier votre email avant de pouvoir vous connecter';
      case 'COMPANY_SUFFIX_EMAIL_NOT_FOUND':
        return "Le domaine de votre email n'est pas  authorisé";
      default:
        return 'Une erreur est survenue';
    }
  }

  getCurrentUser$(): Observable<User> {
    return from(
      axios.get((window as any).env.REACT_APP_BACK_WEB_URL + '/users/me')
    ).pipe(map((res) => res.data));
  }

  sendVerifEmail(email: string) {
    return fromRequest(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/auth/resend-verification-email`,
        {
          email,
        }
      )
    );
  }

  verifyEmail(code: string) {
    return from(
      axios.post(
        `${(window as any).env.REACT_APP_BACK_WEB_URL}/auth/verify-account`,
        {
          code,
        }
      )
    ).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  forgotPassword(email: string) {
    return from(
      axios.get(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/auth/forgot-password/${email}`
      )
    ).pipe(map((res) => res.data));
  }

  resetPassword(resetPassword: ResetPassword) {
    return from(
      axios.post(
        `${(window as any).env.REACT_APP_BACK_WEB_URL}/auth/reset-password`,
        {
          ...resetPassword,
        }
      )
    ).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  registerUser(user: UserToInvite) {
    const userFormData = formDataHelper(user);

    // const userFormData = new FormData()
    // userFormData.append('firstName', user.firstName)
    // userFormData.append('lastName', user.lastName)
    // userFormData.append('email', user.email)
    // userFormData.append('password', user.password)
    // userFormData.append('city', user.city)
    // userFormData.append('companyId', user.companyId + '')
    // userFormData.append('managerId', user.managerId + '')
    // userFormData.append('position', user.position)
    // userFormData.append('file', user.file)
    // userFormData.append('role', user.role)

    return from(
      axios.post(
        `${(window as any).env.REACT_APP_BACK_WEB_URL}/auth/register`,
        userFormData,
        {
          headers: { 'Content-Type': undefined },
        }
      )
    ).pipe(
      tap(
        (res) => {
          /* Do nothing with result ?*/
        },
        (err) => {
          throw new Error(this.handleError(err.response.data.message));
        }
      ),
      switchMap(() => {
        return this.sendVerifEmail(user.email);
      }),
      map((res) => {
        return res.data;
      })
    );
  }

  //   _____ _                   _          _
  //  /  ___| |                 | |        | |
  //  \ `--.| |_ ___  _ __ ___  | |__   ___| |_ __   ___ _ __ ___
  //   `--. \ __/ _ \| '__/ _ \ | '_ \ / _ \ | '_ \ / _ \ '__/ __|
  //  /\__/ / || (_) | | |  __/ | | | |  __/ | |_) |  __/ |  \__ \
  //  \____/ \__\___/|_|  \___| |_| |_|\___|_| .__/ \___|_|  |___/
  // 										| |
  // 										|_|

  toggleMobilePanel(isOpen: boolean = null): Observable<Session> {
    // console.log('toggleMobilePanel', isOpen)
    return this.sessionStore.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        isMobilePanelOpen:
          isOpen !== null ? isOpen : !state.ui.isMobilePanelOpen,
      },
    }));
  }

  setSettingsHelpSectionFocus(
    settingsHelpSectionFocused: SettingsHelpSection
  ): Observable<Session> {
    return this.sessionStore.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        settingsHelpSectionFocused,
      },
    }));
  }
}

export const sessionService = new SessionService(sessionStore);
