import { QueryEntity } from '@datorama/akita';

import { map } from 'rxjs/operators';

import { capsuleQuery } from '../capsule/capsule.query';
import { SolutionState, SolutionStore, solutionStore } from './solution.store';

export class SolutionQuery extends QueryEntity<SolutionState> {
  constructor(protected store: SolutionStore) {
    super(store);
  }

  isUploadingDocument$ = this.select((state) => state.ui.isUploadingDocument);
  solutionsOfAllCapsules$ = this.selectAll();
  activeCapsuleSolutions$ = this.selectAll().pipe(
    // tap(solutions => console.log('capsuleSolutions', capsuleQuery.getActiveId(), solutions)),
    map((solutions) =>
      solutions
        .filter((solution) => solution.capsuleId === capsuleQuery.getActiveId())
        .sort((a, b) => b.nbVoteUp - b.nbVoteDown - (a.nbVoteUp - a.nbVoteDown))
    )
  );
}

export const solutionQuery = new SolutionQuery(solutionStore);
