import { ID } from '@datorama/akita';

import { User } from '../../user/user-permissions.model';
import { CapsuleParticipatingUser } from '@zdlibs/domain/user';

export interface PostCapsuleCommentReturnDto {
  id: number;
  comment: CapsuleComment;
  nbComments: number;
  capsulePoints: number;
  nbUsersParticipating: number;
  participatingUsers: CapsuleParticipatingUser[];
  nbRatings: number;
  totalRatings: number;
  hasUserGivenFeedback: boolean;
}

export interface CapsuleComment {
  id: ID;
  text: string;
  rate: number;
  commentedBy: User;
  commentedAt: Date;
  isProgrammatic?: boolean;
}

export interface RealComment
  extends Omit<CapsuleComment, 'rate' | 'isProgrammatic'> {}

export interface RealFeedback
  extends Omit<CapsuleComment, 'isProgrammatic' | 'text' | 'rate'> {
  text?: string;
  rate: number;
}
export type CommentTypes = 'comment' | 'feedback';
export type CapsuleMessage = RealComment | RealFeedback;

export const isRealComment = (comment: CapsuleComment) =>
  !!comment.text && !comment.isProgrammatic && !comment.rate;
export const isRealFeedback = (comment: CapsuleComment) =>
  !comment.isProgrammatic && comment.rate !== 0;

export const getRealComments = (comments?: CapsuleComment[]): RealComment[] =>
  comments ? comments.filter((c) => isRealComment(c)) : [];

export const getRealFeedbacks = (comments?: CapsuleComment[]): RealFeedback[] =>
  comments ? comments.filter((c) => isRealFeedback(c)) : [];

export const getRealCommentsCount = (comments: CapsuleComment[]) =>
  getRealComments(comments).length || 0;
