import {
  getOldXDays,
  isAfter45DaysOld,
  isBeetween15And30DaysOld,
  isBeetween30And45DaysOld,
} from '../../capsule/capsule-date/capsule-date.model';
import { getMs } from '../../../utils/date';
import { Capsule } from '../../capsule/capsule.model';
import { Search } from '../search.model';
import * as R from 'ramda';

export const filterFraicheur = (
  capsules: Capsule[],
  search: Search
): Capsule[] => {
  if (!search.showOld15Days && !search.showOld30Days && !search.showOld45Days)
    return capsules;
  const orignalCapsules = capsules;
  const filterAndMerge =
    (caps: Capsule[]) =>
    (filter: Predicate<Capsule>): Capsule[] =>
      R.union(caps, R.filter(filter)(orignalCapsules));
  capsules = [];
  if (search.showOld15Days === true) {
    const oldXDays = getOldXDays(new Date());
    capsules = filterAndMerge(capsules)((c) =>
      isBeetween15And30DaysOld(getMs(c.createdAt), oldXDays(15), oldXDays(30))
    );
  }
  if (search.showOld30Days === true) {
    const oldXDays = getOldXDays(new Date());
    capsules = filterAndMerge(capsules)((c) =>
      isBeetween30And45DaysOld(getMs(c.createdAt), oldXDays(30), oldXDays(45))
    );
  }
  if (search.showOld45Days === true) {
    const oldXDays = getOldXDays(new Date());
    capsules = filterAndMerge(capsules)((c) =>
      isAfter45DaysOld(getMs(c.createdAt), oldXDays(45))
    );
  }
  return capsules;
};
