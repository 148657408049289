import { Capsule } from '../capsule.model';
import { getMs, MilliSec, removeDayToDate } from '../../../utils/date';

export const isLess15DaysOld = (
  createdCapsuleDate: MilliSec,
  old15Days: MilliSec
): boolean => createdCapsuleDate > old15Days;

export const isBeetween15And30DaysOld = (
  createdCapsuleDate: MilliSec,
  old15Days: MilliSec,
  old30Days: MilliSec
) => createdCapsuleDate >= old30Days && createdCapsuleDate < old15Days;

export const isBeetween30And45DaysOld = (
  createdCapsuleDate: MilliSec,
  old30Days: MilliSec,
  old45Days: MilliSec
) => createdCapsuleDate >= old45Days && createdCapsuleDate < old30Days;

export const isAfter45DaysOld = (
  createdCapsuleDate: MilliSec,
  old45Days: MilliSec
): boolean => createdCapsuleDate < old45Days;

export const getOldXDays =
  (today: Date) =>
  (days: number): MilliSec =>
    getMs(removeDayToDate(today, days));

export const getFraicheurLabel = (createdAt: Capsule['createdAt']) => {
  const capsuleDate = getMs(createdAt);
  const oldXDays = getOldXDays(new Date());

  if (isLess15DaysOld(capsuleDate, oldXDays(15))) return '-15j';
  if (isBeetween15And30DaysOld(capsuleDate, oldXDays(15), oldXDays(30)))
    return '+15j';
  if (isBeetween30And45DaysOld(capsuleDate, oldXDays(30), oldXDays(45)))
    return '+30j';
  if (isAfter45DaysOld(capsuleDate, oldXDays(45))) return '+45j';
};
