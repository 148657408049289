export const ROOT = '/';

// PUBLIC ROUTES
export const SIGN_IN = '/login';
export const SIGN_OUT = '/logout';
export const SIGN_IN_LINK = '/login-link';
export const SIGN_IN_VERIF = '/login-link-verification';
export const FORGOTTEN_PASSWORD = '/forgot-password';
export const SIGN_UP = '/signup';
export const EMAIL_VERIF = '/verify-email'; // Warning: this URL is referenced by the backend.
export const RESET_PASSWORD = '/reset-password'; // Warning: this URL is referenced by the backend.

// ROUTES WITH NO PARAMETERS AND NOT MODALS
export const HOME = '/home';
export const MY_TEAM = `${HOME}/my-team`;
export const COLLABORATOR = (userId: string | number) => `${MY_TEAM}/${userId}`;
export const COLLABORATOR_WITH_ID = `${HOME}/my-team/:userId`;
export const CONSTELLATIONS = `${HOME}/constellations`;
export const DASHBOARD = `${HOME}/dashboard`;
export const NOTE_LIST = `${HOME}/noteList`;

// SETTINGS
export const SETTINGS = '/settings';

// MODALS WITH A SIMPLE ROUTE AND NO PARAMETERS
export const INVITEUSER = `${MY_TEAM}/invite-user`;

export const FRAGMENT__CHOOSE_VALIDATORS = '/validators';

// TODO:
export const FRAGMENT__ADD_SOLUTION = '/add-solution';
export const FRAGMENT__SHARE_CAPSULE_TO_USERS = '/share-to-users';
export const FRAGMENT__SHARE_CAPSULE_TO_CONSTELLATIONS =
  '/share-to-constellations';

// CAPSULE DETAIL AND ITS MODALS
export const CAPSULE_LIST = `${HOME}/capsules`;
export const CAPSULE_DETAIL = (capsuleId: string | number) =>
  `${HOME}/capsules/${capsuleId}`;
export const CAPSULE_DETAIL_WITH_ID = `${HOME}/capsules/:capsuleId`;

export const CAPSULE_DETAIL_ADD_SOLUTION = (routeMatchUrl: string) =>
  `${routeMatchUrl}${FRAGMENT__ADD_SOLUTION}`;

// CONSTELLATION DETAIL AND ITS MODALS
export const CONSTELLATION_DETAIL = (constellationId: string | number) =>
  `${HOME}/constellations/${constellationId}`;
export const CONSTELLATION_DETAIL_WITH_ID = `${HOME}/constellations/:constellationId`;

export const VALIDATORS_CHOICE = (tagId: string | number) =>
  `${SETTINGS}/tag/${tagId}${FRAGMENT__CHOOSE_VALIDATORS}`;
