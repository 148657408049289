import { Capsule } from '@store/capsule/capsule.model';
import { RecapItemProps } from '../../molecules/RecapPage/RecapItem';
import {
  countCapsulesByStatus,
  isArchived,
  isInProgress,
  isNew,
  isNotResolved,
  isPending,
  isResolved,
} from '@store/capsule/capsule-status/capsule-status.model';

export const recapCapsuleList =
  (capsules: Capsule[]) =>
  (
    isValidationEnabled: boolean,
    isMyCapsulesHome: boolean
  ): RecapItemProps[] => {
    if (!capsules || capsules.length === 0) return [];
    capsules = capsules.filter(
      (capsule) =>
        !isArchived(capsule) && (isMyCapsulesHome ? capsule.myCapsule : true)
    );
    const counter = countCapsulesByStatus(capsules);

    const stats = [
      { label: 'capsules', data: capsules.length },
      { label: 'nouvelles', data: counter(isNew) },
      { label: 'en progression', data: counter(isInProgress) },
      { label: 'résolues', data: counter(isResolved) },
      { label: 'non résolues', data: counter(isNotResolved) },
      { label: 'archivées', data: counter(isArchived) },
    ];
    if (isValidationEnabled) {
      stats.splice(2, 0, {
        label: 'en attente',
        data: counter(isPending),
      });
    }
    return stats;
  };
