import React, { useEffect, useState } from 'react';

import { OutlinedInput, InputAdornment } from '@material-ui/core';
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

// Prefer theme overrides for input style: apps/react-client/src/theme/theme.ts

export type TextInputOnChange = (text: string | number | unknown) => void;

export type TextInputViewProps = InputBaseProps & {
  defaultValue?: string | number;
  onChange?: TextInputOnChange;
  onBlur?: (text: string | number) => void;
  isRequired?: boolean;
};

const TextInputView = ({
  defaultValue,
  onChange,
  onBlur,
  fullWidth = true,
  isRequired,
  type,
  ...props
}: TextInputViewProps) => {
  const [value, setValue] = useState(defaultValue || '');

  useEffect(() => {
    onChange && onChange(value);
  }, [value]);

  if (type === 'search') {
    return (
      <InputBase
        value={value}
        type={type}
        onChange={(event) => setValue(event.target.value)}
        onBlur={(event) => onBlur && onBlur(event.target.value)}
        startAdornment={
          type === 'search' && (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }
        {...props}
      />
    );
  } else {
    return (
      <OutlinedInput
        required={isRequired}
        value={value}
        type={type}
        onChange={(event) => setValue(event.target.value)}
        onBlur={(event) => onBlur && onBlur(event.target.value)}
        inputProps={{
          max: 1000,
          min: 0,
          borderBottom: 0,
        }}
        fullWidth={fullWidth}
        {...props}
      />
    );
  }
};

export default TextInputView;
