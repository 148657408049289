import { ID } from '@datorama/akita';
import axios, { AxiosResponse } from 'axios';
import { from, Observable, take } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { get } from '../../utils/axios';
import { sessionStore } from '../session/session.store';
import { tagStore } from '../tag/tag.store';
import { UserToInvite } from './user-to-invite.model';
import { User, UserInfoExtends } from './user-permissions.model';
import { userQuery } from './user.query';
import { UserStore, userStore } from './user.store';

export class UserService {
  constructor(private userStore: UserStore) {}

  patchUserMe(userId: ID, updatedFields: Partial<User>): Observable<User> {
    return from(
      axios.patch(
        `${(window as any).env.REACT_APP_BACK_WEB_URL}/users/me`,
        updatedFields
      )
    ).pipe(
      tap((res) => {
        this.userStore.upsert(userId, (user) => {
          return {
            ...user,
            ...res.data,
          };
        });
        sessionStore.updateUser(res.data);
      }),
      map((res) => {
        return userQuery.getEntity(res.data.id);
      })
    );
  }

  getCompanyUsers(): Observable<User[]> {
    return from(get('users')).pipe(
      tap((res) => {
        this.userStore.add(res.data);
      }),
      map((res) => res.data)
    );
  }

  getUsersInfoExtends(): Observable<UserInfoExtends[]> {
    return from(get('users/info-extends')).pipe(
      tap((res: AxiosResponse<UserInfoExtends[]>) => {
        this.userStore.upsertMany(res.data as unknown as User[]);
      }),
      map((res) => res.data),
      take(1)
    );
  }

  invite(newUser: UserToInvite): Observable<User> {
    return from(
      axios.post(
        `${(window as any).env.REACT_APP_BACK_WEB_URL}/users/invite`,
        newUser
      )
    ).pipe(
      tap((res) => {
        if (res.data && res.data.id) {
          this.userStore.add(res.data);
        }
      }),
      map((res) => {
        return userQuery.getEntity(res.data.id);
      }),
      take(1)
    );
  }

  delete(userId: ID): Observable<User> {
    return from(
      axios.delete(
        `${(window as any).env.REACT_APP_BACK_WEB_URL}/users/${userId}`
      )
    ).pipe(
      tap((res) => {
        this.userStore.update(userId, (user) => {
          return {
            ...user,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return userQuery.getEntity(res.data.id);
      })
    );
  }

  setUploadingImage(isUploadingImage: boolean): Observable<User> {
    return this.userStore.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        isUploadingImage,
      },
    }));
  }

  patchUserMeImage(userId: ID, file: File): Observable<User> {
    const formData = new FormData();
    formData.append('file', file);

    return from(
      axios.patch(
        `${(window as any).env.REACT_APP_BACK_WEB_URL}/users/me/image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
    ).pipe(
      tap((res) => {
        this.userStore.upsert(userId, (user) => {
          return {
            ...user,
            ...res.data,
          };
        });
        sessionStore.updateUser(res.data);
        this.setUploadingImage(false);
      }),
      map((res) => {
        return userQuery.getEntity(res.data.id);
      })
    );
  }

  addTag(userId: ID, tagId: ID): Observable<User> {
    return from(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/users/${userId}/tag/${tagId}`
      )
    ).pipe(
      tap((res) => {
        this.userStore.update(userId, (user) => {
          return {
            ...user,
            tags: user.tags ? [...user.tags, res.data] : [res.data],
          };
        });
      }),
      tap((res) => {
        tagStore.update(tagId, (tag) => {
          return {
            ...tag,
            validators: tag.validators
              ? [...tag.validators, userQuery.getEntity(userId)]
              : [],
          };
        });
      }),
      map((res) => {
        return userQuery.getEntity(userId);
      })
    );
  }

  removeTag(userId: ID, tagId: ID): Observable<User> {
    return from(
      axios.delete(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/users/${userId}/tag/${tagId}`
      )
    ).pipe(
      tap((res) => {
        this.userStore.update(userId, (user) => {
          return {
            ...user,
            tags: user.tags ? user.tags.filter((t) => t.id !== res.data) : [],
          };
        });
      }),
      tap((res) => {
        tagStore.update(tagId, (tag) => {
          return {
            ...tag,
            validators: tag.validators
              ? tag.validators.filter((t) => t.id !== userId)
              : [],
          };
        });
      }),
      map((res) => {
        return userQuery.getEntity(userId);
      })
    );
  }

  // getPeers(): Observable<User[]> {
  // 	return from(axios.get(`${(window as any).env.REACT_APP_BACK_WEB_URL}/users/me/peers`)).pipe(
  // 		tap(res => this.userStore.add(res.data)),
  // 		map(res => res.data),
  // 	)
  // }

  // getCandidatesForPropel(capsuleId: string): Observable<PropelCandidate[]> {
  // 	return from(axios.get(`${(window as any).env.REACT_APP_BACK_WEB_URL}/capsules/${capsuleId}/propel-candidates`)).pipe(
  // 		tap(res => {
  // 			this.userStore.add(res.data)
  // 		}),
  // 		map(res => res.data),
  // 	)
  // }

  // getTeam(): Observable<User[]> {
  // 	return from(axios.get(`${(window as any).env.REACT_APP_BACK_WEB_URL}/users/me/team`)).pipe(map(res => res.data))
  // }

  // getPeers(): Observable<User[]> {
  // 	return from(axios.get(`${(window as any).env.REACT_APP_BACK_WEB_URL}/users/me/peers`)).pipe(map(res => res.data))
  // }

  // getManager(): Observable<User> {
  // 	return from(axios.get(`${(window as any).env.REACT_APP_BACK_WEB_URL}/users/me/manager`)).pipe(map(res => res.data))
  // }

  // updatePicture(picture: File) {
  // 	const pictureFormData = new FormData()
  // 	pictureFormData.append('picture', picture)
  // 	return fromRequest(
  // 		axios.patch(`${(window as any).env.REACT_APP_BACK_WEB_URL}/users/me/picture`, pictureFormData, {
  // 			headers: { 'Content-Type': undefined },
  // 		}),
  // 	).pipe(
  // 		map(res => {
  // 			sessionStore.updateUser(res.data)
  // 			return res.data
  // 		}),
  // 	)
  // }
}

export const userService = new UserService(userStore);
