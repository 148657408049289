import { ID } from '@datorama/akita';

import { Company } from '../company/company.model';
import { Tag } from '../tag/tag.model';
import { isNew } from '../capsule/capsule-status/capsule-status.model';
import { Capsule } from '../capsule/capsule.model';
import { Constellation } from '../constellation/constellation.model';
import {
  isCapsuleCreator,
  isCapsulePilote,
  isParticipatingUser,
  isReferentOrAdmin,
  isUser,
  UserRole,
} from '@zdlibs/domain/user';
import { CapsuleMessage } from '../capsule/capsule-comment/capsule-comment.model';

export interface User {
  id: ID;
  companyId: ID;
  firstName: string;
  lastName: string;
  email: string;
  city: string;
  profilePhoto: string;
  profilePhotoThumb?: string;
  position: string;
  phone: string;
  userPoints: number;
  isNotifiedOnOwnedCapsuleChanges: boolean;
  isNotifiedOnTrendingCapsules: boolean;
  isNotifiedOnConstellationsActivity: boolean;
  isNotifiedOnMentions: boolean;
  lastLoggedAt: Date;
  createdAt: Date;
  verificationCode?: string;
  verified?: boolean;
  enabled?: boolean;
  deleted?: boolean;
  tags?: Tag[];
  role: UserRole;
  company: Company;
}

export interface UserInfoExtends {
  id: ID;
  createdSolutionsCount: number;
  createdCapsulesCount: number;
}

export type ExtendedUser = User & UserInfoExtends;
export const isAllowToEditMembers = (user: User) => isReferentOrAdmin(user);

export const isAllowToSeeCapsuleValidation = (capsule: Capsule) =>
  !!capsule?.requireValidation && !isNew(capsule);

export const isAllowToEdit = (user: User, constellation: Constellation) =>
  user &&
  (user.role === UserRole.ADMIN ||
    user.role === UserRole.REFERENT ||
    constellation?.userPermissions?.isReferentConstellation);

export const isAllowedToArchive = (user: User, capsule: Capsule) =>
  isCapsuleCreator(user)(capsule) ||
  isCapsulePilote(user)(capsule) ||
  isReferentOrAdmin(user);

export const getCapsuleParticipatingUsersCount = (capsule: Capsule): number =>
  capsule?.participatingUsers?.length || 0;

export const getCapsuleMembersFromUserList = (
  capsule: Capsule,
  users: User[]
) =>
  capsule?.participatingUsers &&
  capsule.participatingUsers
    .map((cu) => users.find((u) => u.id === cu.participatingUserId))
    .filter(Boolean);

export const isAllowedToShare = (user: User, capsule: Capsule): boolean =>
  isUser(user) &&
  (isCapsuleCreator(user)(capsule) ||
    isCapsulePilote(user)(capsule) ||
    isReferentOrAdmin(user) ||
    isParticipatingUser(user.id)(capsule));

export const isNotAllowedToSeeConstellation = (constellation: Constellation) =>
  !constellation.userPermissions?.allowed;

const isCommentator = (user: User) => (comment: CapsuleMessage) =>
  user.id === comment.commentedBy.id;

export const isAllowToEditComment = (user: User) => (comment: CapsuleMessage) =>
  isCommentator(user)(comment) || user.role === UserRole.ADMIN;

export const isAllowToRemoveComment =
  (user: User) => (comment: CapsuleMessage) =>
    isCommentator(user)(comment) || isReferentOrAdmin(user);
