import { QueryEntity, toBoolean } from '@datorama/akita';

import { SessionState, SessionStore, sessionStore } from './session.store';

export class SessionQuery extends QueryEntity<SessionState> {
  isLoggedIn$ = this.select((state) => {
    return toBoolean(state.accessToken);
  });
  currentUser$ = this.select((state) => state.user);

  isMobilePanelOpen$ = this.select((state) => state.ui.isMobilePanelOpen);
  settingsHelpSectionFocused$ = this.select(
    (state) => state.ui.settingsHelpSectionFocused
  );

  constructor(protected store: SessionStore) {
    super(store);
  }
}

export const sessionQuery = new SessionQuery(sessionStore);
