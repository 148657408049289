import { mergeMap, of, take } from 'rxjs';
import { sessionService } from './session/session.service';
import { userService } from './user/user.service';
import { searchService } from './search/search.service';
import { userNotificationService } from './notification/notification.service';
import { categoryService } from './category/category.service';
import { companyService } from './company/company.service';
import { capsuleService } from './capsule/capsule.service';
import { constellationService } from './constellation/constellation.service';
import { labelService } from './label/label.service';

export const initStore = () =>
  sessionService
    .setHeaderAccessToken()
    .pipe(
      mergeMap((isLogged) => {
        if (!isLogged) {
          return of(new Error('isNotLogged'));
        }
        return userService.getCompanyUsers().pipe(
          mergeMap(() => companyService.getCompany()),
          mergeMap(() => constellationService.getConstellations()),
          mergeMap(() => capsuleService.getCapsules()),
          mergeMap(() => searchService.getSearches()),
          mergeMap(() => userNotificationService.getNotifications()),
          mergeMap(() => categoryService.getCategoriesAndTags()),
          mergeMap(() => labelService.findAll())
        );
      })
    )
    .pipe(take(1));
